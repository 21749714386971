import React, { createContext, useState } from 'react';

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState([]);

  const addToCart = (item) => {
    setCart((prevCart) => {
      // Check if the item is a license (tier or clicks)
      const isLicense = item.id.startsWith('tier_');
      
      // If adding a license, check if there's already a license in the cart
      if (isLicense) {
        // Filter out any existing licenses
        const existingLicense = prevCart.find(
          cartItem => cartItem.id.startsWith('tier_')
        );
        
        if (existingLicense) {
          // If there's already a license, replace it
          const nonLicenseItems = prevCart.filter(
            cartItem => !(cartItem.id.startsWith('tier_'))
          );
          return [...nonLicenseItems, { ...item, quantity: 1 }];
        }
      }
      
      // Check if item already exists
      const existingItemIndex = prevCart.findIndex(cartItem => cartItem.id === item.id);
      
      if (existingItemIndex !== -1) {
        // If item exists, increment quantity
        const updatedCart = [...prevCart];
        updatedCart[existingItemIndex] = {
          ...updatedCart[existingItemIndex],
          quantity: updatedCart[existingItemIndex].quantity + 1
        };
        return updatedCart;
      } else {
        // If item doesn't exist, add it with quantity 1
        return [...prevCart, { ...item, quantity: 1 }];
      }
    });
  };

  const removeFromCart = (itemId) => {
    setCart((prevCart) => prevCart.filter((item) => item.id !== itemId));
  };

  const updateCartItem = (itemId, quantity) => {
    if (quantity <= 0) {
      removeFromCart(itemId);
      return;
    }
    
    setCart((prevCart) =>
      prevCart.map((item) =>
        item.id === itemId ? { ...item, quantity } : item
      )
    );
  };

  return (
    <CartContext.Provider value={{ cart, addToCart, removeFromCart, updateCartItem }}>
      {children}
    </CartContext.Provider>
  );
};