import React, { useState } from 'react';
import user_icon from '../assets/person.png';
import email_icon from '../assets/email.png';
import password_icon from '../assets/password.png';
import location_icon from '../assets/location_icon.png'
import '../loginsignup/loginsignup.css';
import { loginUser, signupUser, saveUserData } from '../../components/services/firebase.service';


const Loginsingup = ({ onBackHome, onLoginSuccess, onPayments }) => {
  const [action, setAction] = useState("Login");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [fullName, setFullName] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [country, setCountry] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleSignUp = async () => {
    try {
      setLoading(true);
      setError("");
      
      // Validate inputs
      if (!email || !password || !fullName || !addressLine1 || !city || !state || !zipCode || !country) {
        throw new Error("Please fill in all required fields");
      }
      
      // Create user with Firebase Auth
      const user = await signupUser(email, password);
      
      // Save additional user data to Realtime Database
      const userData = {
        email,
        displayName: fullName,
        address: {
          line1: addressLine1,
          line2: addressLine2 || "",
          city,
          state,
          postalCode: zipCode,
          country
        },
        createdAt: Date.now()
      };
      
      await saveUserData(user.uid, userData);
      
      // Save to localStorage
      localStorage.setItem('userEmail', email);
      localStorage.setItem('userId', user.uid);
      
      alert('User signed up successfully!');
      onPayments();
    } catch (error) {
      console.error('Signup error:', error);
      setError(error.message);
      alert(`Unable to Sign Up: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleLogin = async () => {
    try {
      setLoading(true);
      setError("");
      
      if (!email || !password) {
        throw new Error("Email and password are required");
      }
      
      // Sign in with Firebase Auth
      const user = await loginUser(email, password);
      
      // Save to localStorage
      localStorage.setItem('userEmail', email);
      localStorage.setItem('userId', user.uid);
      
      onLoginSuccess(email); // Update parent state immediately
    } catch (error) {
      console.error('Error logging in:', error);
      setError(error.message);
      alert(`Unable to Login: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='container'>
      {error && <div className="error-message">{error}</div>}
      <div className='header'>
        <div className="text">{action}</div>
        <div className="underline"></div>
      </div>
      <div className="inputs">
        {action === "Sign Up" && (
          <>
            <div className="input">
              <img src={user_icon} alt="user_icon" className="icon" />
              <input
                type="text"
                placeholder="Full Name"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                required
              />
            </div>
            <div className="input">
              <img src={location_icon} alt="location_icon" className="icon" />
              <input
                type="text"
                placeholder="Address Line 1"
                value={addressLine1}
                onChange={(e) => setAddressLine1(e.target.value)}
                required
              />
            </div>
            <div className="input">
              <img src={location_icon} alt="location_icon" className="icon" />
              <input
                type="text"
                placeholder="Address Line 2 (Optional)"
                value={addressLine2}
                onChange={(e) => setAddressLine2(e.target.value)}
              />
            </div>
            <div className="address-row">
              <div className="input city">
                <input
                  type="text"
                  placeholder="City"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  required
                />
              </div>
              <div className="input state">
                <input
                  type="text"
                  placeholder="State"
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="address-row">
              <div className="input zip">
                <input
                  type="text"
                  placeholder="Zip/Postal Code"
                  value={zipCode}
                  onChange={(e) => setZipCode(e.target.value)}
                  required
                />
              </div>
              <div className="input country">
                <input
                  type="text"
                  placeholder="Country"
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                  required
                />
              </div>
            </div>
          </>
        )}
        
        <div className="input">
          <img src={email_icon} alt="email_icon" className="icon" />
          <input
            type="email"
            placeholder="Email Address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="input">
          <img src={password_icon} alt="password_icon" className="icon" />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
      </div>
      
      {action === "Sign Up" ? <div></div> : <div className="forgot-password">Forgot Password?<span></span></div>}
      
      <div className="submit-container">
        <button
          className={action === "Login" ? "submit gray" : "submit"}
          onClick={() => {
            if (action === "Login") {
              handleLogin();
            } else {
              setAction("Login");
            }
          }}
          disabled={loading}
        >
          Login
        </button>
        <button
          className={action === "Sign Up" ? "submit gray" : "submit"}
          onClick={() => {
            if (action === "Sign Up") {
              handleSignUp();
            } else {
              setAction("Sign Up");
            }
          }}
          disabled={loading}
        >
          Sign Up
        </button>
      </div>
      <div className="back-to-home" onClick={onBackHome}>Back to Home</div>
    </div>
  );
};

export default Loginsingup;