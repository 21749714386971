// src/services/firebase.service.js
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, signOut } from 'firebase/auth';
import { getDatabase, ref, set, get, update, push, remove } from 'firebase/database';



const firebaseConfig = {
  apiKey: "AIzaSyAFJuVgtywX-Zroe5znyphM5n52zXUltMU",
  authDomain: "thegradysensor.firebaseapp.com",
  databaseURL: "https://thegradysensor-default-rtdb.firebaseio.com",
  projectId: "thegradysensor",
  storageBucket: "thegradysensor.firebasestorage.app",
  messagingSenderId: "599372392756",
  appId: "1:599372392756:web:080293332f4b0f0bacdd61",
  measurementId: "G-TWSNTDYFTD"
};






// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const database = getDatabase(app);

// Auth functions
export const loginUser = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    return userCredential.user;
  } catch (error) {
    throw error;
  }
};

export const signupUser = async (email, password) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    return userCredential.user;
  } catch (error) {
    throw error;
  }
};

export const logoutUser = () => {
  return signOut(auth);
};

// Database functions
export const saveUserData = async (userId, userData) => {
  try {
    const userRef = ref(database, `users/${userId}`);
    await set(userRef, {
      ...userData,
      updatedAt: Date.now()
    });
    return true;
  } catch (error) {
    console.error('Error saving user data:', error);
    throw error;
  }
};

export const getUserData = async (userId) => {
  try {
    const userRef = ref(database, `users/${userId}`);
    const snapshot = await get(userRef);
    if (snapshot.exists()) {
      return snapshot.val();
    }
    return null;
  } catch (error) {
    console.error('Error getting user data:', error);
    throw error;
  }
};

export const getUserByEmail = async (email) => {
  try {
    // This is a simplified approach - in production, you'd use proper indexing
    // or server-side query for this operation
    const usersRef = ref(database, 'users');
    const snapshot = await get(usersRef);
    if (snapshot.exists()) {
      let foundUser = null;
      snapshot.forEach((child) => {
        const userData = child.val();
        if (userData.email === email) {
          foundUser = { ...userData, uid: child.key };
        }
      });
      return foundUser;
    }
    return null;
  } catch (error) {
    console.error('Error getting user by email:', error);
    throw error;
  }
};

const getOrderDetails = async (userId, orderId) => {
  try {
    const orderRef = ref(database, `orders/${userId}/${orderId}`);
    const snapshot = await get(orderRef);
    if (snapshot.exists()) {
      return snapshot.val();
    }
    return null;
  } catch (error) {
    console.error(`Error getting order details for ${orderId}:`, error);
    throw error;
  }
};

export { auth, database, getOrderDetails };