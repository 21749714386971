import React from 'react';
import './landingpage.css';
import logo from '../assets/Sensor.png';

const LandingPage = ({ onSignUp, onLearnMore }) => {
  return (
    <div className="landing-page">
      <header className="landing-header">
        <img src={logo} className="landing-logo" alt="logo" />
        <h1>The Grady Seed Moisture Sensor </h1>
        <p>Measuring seed moisture content in seconds </p>
        <div className="cta-container">
          <button className="cta-button primary" onClick={onSignUp}>
            BUY NOW!!
          </button>
          <button className="cta-button secondary" onClick={onLearnMore}>
            Learn More
          </button>
        </div>
      </header>
    </div>
  );
};

export default LandingPage;
