import './App.css';
import React, { useState } from 'react';
import Loginsingup from './components/loginsignup/loginsingup';
import LandingPage from './components/landingpage/landingpage';
import LearnMore from './components/learnmore/learnmore';
import Payments from './components/paymentspage/payments';
import Reports from './components/reports/reports'; // Add this import
import Navbar from './components/navbar/navbar';
import Dashboard from './components/dashboard/dashboard';

function App() {
  const [currentView, setCurrentView] = useState('landing');
  const [userEmail, setUserEmail] = useState(localStorage.getItem('userEmail'));

  const handleHome = () => {
    setCurrentView('landing');
  }

  const handleSignUp = () => {
    setCurrentView('loginSignup');
  };

  const handleLearnMore = () => {
    setCurrentView('learnMore');
  };

  const handleBackHome = () => {
    setCurrentView('landing');
  };

  const handlePayments = () => {
    setCurrentView('payments');
  };

  const handleSignIn = () => {
    setCurrentView('loginSignup');
  }

  const handleLoginSuccess = (email) => {
    setUserEmail(email);
    setCurrentView('payments');
  }

  const handleReports = () =>{
    setCurrentView('reports');
  }

  const handleDashboard = () =>{
    setCurrentView('dashboard')
  }
  return (
    <div className="App">
      <Navbar 
        userEmail={userEmail} 
        onSignIn={handleSignIn} 
        onPayments={handlePayments}
        onHome={handleHome}
        onLearnMore={handleLearnMore}
        onDashboard={handleDashboard}
        onReports={handleReports}
      />
      {currentView === 'landing' && (
        <LandingPage onSignUp={handlePayments} onLearnMore={handleLearnMore} />
      )}
      {currentView === 'loginSignup' && (
        <Loginsingup onBackHome={handleBackHome} onLoginSuccess={handleLoginSuccess} onPayments={handlePayments}/>
      )}
      {currentView === 'learnMore' && (
        <LearnMore onBackHome={handleBackHome} />
      )}
      {currentView === 'reports' && (
        <Reports onBackHome={handleBackHome} />
      )}
      {currentView === 'payments' && (
        <Payments onBackHome={handleBackHome}
        onSignIn={handleSignIn}
        onReports={handleReports}/>
      )}
            {currentView === 'success' && (
        <div className="success-message">
          <h2>Payment Successful!</h2>
          <button onClick={handleBackHome}>Return Home</button>
        </div>
      )}
      {currentView === 'dashboard' && (
        <Dashboard onBackHome={handleBackHome} />
      )}
      {currentView === 'cancel' && (
        <div className="cancel-message">
          <h2>Payment Cancelled</h2>
          <button onClick={() => setCurrentView('payments')}>Try Again</button>
        </div>
      )}
    </div>
  );
}

export default App;