import React, { useContext, useState } from 'react';
import { CartContext } from '../cart/cartContext';
import { 
  isLicenseAvailable, 
  getLicenseAvailabilityDate, 
  isPreorderAvailable 
} from '../config/product-config';
import './payments.css';
import logo from '../assets/Sensor.png';

// Add state for Terms popup
const Payments = ({ onBackHome, onSignIn, onReports }) => {
  const { cart, addToCart, removeFromCart, updateCartItem} = useContext(CartContext);
  const [showCart, setShowCart] = useState(false);
  const [isProcessingOrder, setIsProcessingOrder] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('credit_card');
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [showTermsPopup, setShowTermsPopup] = useState(false); // Add this line

  // Get license availability date
  const licenseAvailableDate = getLicenseAvailabilityDate();
  const licensesAvailable = isLicenseAvailable();
  const preorderAvailable = isPreorderAvailable();

  const handlePaymentMethodChange = (event) => {
    setPaymentMethod(event.target.value);
  }

  const handleTermsChange = (event) => {
    setAcceptedTerms(event.target.checked);
  };
  
  // Add these functions for the Terms popup
  const toggleTermsPopup = () => {
    setShowTermsPopup(!showTermsPopup);
  };
  
  const closePopup = (e) => {
    if (e.target.className === 'popup-overlay') {
      setShowTermsPopup(false);
    }
  };

  const handleCreditCardCheckout = async () => {
    // Prevent double-clicks
    if (isProcessingOrder) return;

    if (!acceptedTerms) {
      alert('Please accept the Terms and Conditions to proceed.');
      return;
    }

    setIsProcessingOrder(true);
    
    try {
      // Check if user is authenticated
      if (!isAuthenticated()) {
        alert('Please sign in to pre-order');
        onSignIn();
        setIsProcessingOrder(false);
        return;
      }
      
      // Create a direct checkout for the pre-order
      const response = await fetch(`https://us-central1-thegradysensor.cloudfunctions.net/api/payment/create-checkout`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          cartItems: [{
        productId: 'device',
        quantity: 1
          }],
          userId: localStorage.getItem('userId')
        })
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to create checkout session');
      }
      
      const { url } = await response.json();
      
      // Redirect to Stripe Checkout
      window.location.href = url;
      
    } catch (error) {
      console.error('Pre-order checkout error:', error);
      alert('Error: ' + error.message);
      setIsProcessingOrder(false);
    }
  };

  const handleACHOrChequePayment = async () => {
    if (isProcessingOrder) return;
    setIsProcessingOrder(true);
    
    try {
      if (!isAuthenticated()) {
        alert('Please sign in to proceed with payment');
        onSignIn();
        setIsProcessingOrder(false);
        return;
      }
      
      const response = await fetch(`https://us-central1-thegradysensor.cloudfunctions.net/api/payment/send-payment-details`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          paymentMethod,
          userEmail: localStorage.getItem('userEmail')
        })
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to send payment details');
      }
      
      alert('Payment details have been sent to your email.');
      setIsProcessingOrder(false);
      
    } catch (error) {
      console.error('Payment error:', error);
      alert('Error: ' + error.message);
      setIsProcessingOrder(false);
    }
  };
  
  const handlePayment = () => {
    if (paymentMethod === 'credit_card') {
      handleCreditCardCheckout();
    } else {
      handleACHOrChequePayment();
    }
  };
  const isAuthenticated = () => {
    return localStorage.getItem('userEmail') !== null;
  };

  const handleAddToCart = async (item) => {
    if(!isAuthenticated()){
      alert('Please Sign in to add items to cart');
      onSignIn();
      return;
    }

    try {
      const response = await fetch(`https://us-central1-thegradysensor.cloudfunctions.net/api/payment/validate-purchase`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: localStorage.getItem('userId'),
          productId: item.id
        })
      });
      
      const data = await response.json();
      
      if (!data.valid) {
        alert(data.error);
        return;
      }
      
      // Check if item is available
      const isLicenseTier = ['tier_1','tier_2', 'tier_3'].includes(item.id);
      if (isLicenseTier && !licensesAvailable) {
        alert(`Licenses will be available from ${licenseAvailableDate}`);
        return;
      }
  
      // Rest of your existing cart logic
      const existingIndex = cart.findIndex(cartItem => cartItem.id === item.id);
      
      if (existingIndex >= 0) {
        updateCartItem(item.id, cart[existingIndex].quantity + 1);
      } else {
        // Check for existing license in cart
        const existingLicense = cart.find(cartItem => 
          ['tier_1', 'tier_2', 'tier_3'].includes(cartItem.id)
        );
        
        if (
          ['tier_1', 'tier_2', 'tier_3'].includes(item.id) && 
          existingLicense
        ) {
          const confirmReplace = window.confirm(
            `You already have ${existingLicense.name} in your cart. Replace it with ${item.name}?`
          );
          
          if (!confirmReplace) {
            return;
          }
          
          // Remove existing license
          removeFromCart(existingLicense.id);
        }
        
        // Add item to cart with quantity of 1
        addToCart({...item, quantity: 1});
      }
      
      // Show cart after adding
      setShowCart(true);
    } catch (error) {
      console.error('Error validating purchase:', error);
      alert('Error: ' + error.message);
    }
  };

  const handleRemoveFromCart = (itemId) => {
    removeFromCart(itemId);
  };

  const handleUpdateCartItem = (itemId, quantity) => {
    if (quantity < 1) {
      removeFromCart(itemId);
    } else {
      updateCartItem(itemId, quantity);
    }
  };

  const total = cart.reduce((sum, item) => sum + item.price * item.quantity, 0);

  const handlePaymentClick = async (priceId, amount) => {
    if (!isAuthenticated()) {
      alert('Please sign in to complete your purchase');
      onSignIn();
      return;
    }

    // Check if licenses are available
    if (priceId !== 'device' && !licensesAvailable) {
      alert(`Licenses will be available from ${licenseAvailableDate}`);
      return;
    }

    try {
      // Validate product purchase
      const response = await fetch(`https://us-central1-thegradysensor.cloudfunctions.net/api/payment/validate-purchase`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: localStorage.getItem('userId'),
          productId: priceId
        })
      });
      
      const data = await response.json();
      
      if (!data.valid) {
        alert(data.error);
        return;
      }
      
      handleStripeCheckout(priceId, amount);
    } catch (error) {
      console.error('Payment validation error:', error);
      alert('Error: ' + error.message);
    }
  };

  const handleStripeCheckout = async (priceId, amount) => {
    if (cart.length === 0){
      alert('Your cart is empty');
      return;
    }
    if (!isAuthenticated()){
      alert('Please sign in to checkout');
      onSignIn();
      return;
    }
    try {
      // Format cart items for backend
      const cartItems = cart.map(item => ({
        productId: item.id,
        quantity: item.quantity
      }));
  
      const response = await fetch(`https://us-central1-thegradysensor.cloudfunctions.net/api/payment/create-checkout`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          cartItems: cartItems,
          userId: localStorage.getItem('userId')
        })
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to create checkout session');
      }
  
      const { url } = await response.json();
      window.location.href = url;
    } catch (error) {
      console.error('Payment error:', error);
      alert('Payment failed: ' + error.message);
    }
  };

  const toggleCart = () => {
    setShowCart(!showCart);
  };

  return (
    <div className="payments-container">
      <h1 className="sensor-title">The Grady Sensor Products & Pricing</h1>
      
      {/* Cart Toggle Button
      <button 
        className="cart-toggle-button" 
        onClick={toggleCart}
      >
        {showCart ? 'Hide Cart' : `View Cart (${cart.length})`}
      </button> */}
      
      {/* Cart View */}
      {showCart && (
        <div className="cart-container">
          <h2>Your Cart</h2>
          {cart.length === 0 ? (
            <p>Your cart is empty</p>
          ) : (
            <>
              <div className="cart-items">
                {cart.map((item) => (
                  <div key={item.id} className="cart-item">
                    <div className="item-info">
                      <h3>{item.name}</h3>
                      <p className="price">${item.price.toFixed(2)}</p>
                    </div>
                    <div className="item-controls">
                      <button 
                        className="quantity-button"
                        onClick={() => handleUpdateCartItem(item.id, item.quantity - 1)}
                      >
                        -
                      </button>
                      <span className="quantity">{item.quantity}</span>
                      <button 
                        className="quantity-button"
                        onClick={() => handleUpdateCartItem(item.id, item.quantity + 1)}
                      >
                        +
                      </button>
                      <button 
                        className="remove-button"
                        onClick={() => handleRemoveFromCart(item.id)}
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                ))}
              </div>
              <div className="cart-summary">
                <h3>Total: ${total.toFixed(2)}</h3>
                <button 
                  className="checkout-button"
                  onClick={() => handleStripeCheckout('cart_checkout', total)}
                  disabled={!isAuthenticated()}
                >
                  {isAuthenticated() ? 'Proceed to Checkout' : 'Sign in to Checkout'}
                </button>
              </div>
            </>
          )}
        </div>
      )}
      
      {/* Device Section */}
      <div className="product-card device-card">
  <img src={logo} alt="Grady Sensor" className="device-image" />
  <div className="product-details">
    <h2>The Grady Sensor</h2>
    <p className="price">
      $4,299.00 USD<span className="flashing-text">(Pre-Order NOW for $2,000 USD!)</span>
    </p>
    {/* <p className='price-2'>Plus $30 Processing Fee</p> */}
    
    <div className="supported-breeds">
      <h4>Optimized for the following grass species:</h4>
      
      <ul className="breeds-list">
        <li>Tall Fescue</li>
        <li>Perennial Ryegrass</li>
        <li>Annual Ryegrass</li>
        <li>Orchardgrass</li>
        {/* <li>Creeping Bentgrass</li> */}
      </ul>
      <button onClick={() =>onReports()}className="report-button"> <strong>Please check testing reports to learn more!</strong></button>

    </div>
    
    <div className="device-promotion">
      <p>
        <span className="flashing-text">🔥 FREE one-year Tier 1 license with UNLIMITED MEASUREMTNS for your device! 🔥</span>
      </p>
      </div>
    
    <div className="button-group">
      {preorderAvailable && (
        <>
          {/* <button 
            onClick={() => handleAddToCart({
              id: 'device',
              name: 'Grady Sensor Device',
              price: 2000.00
            })}
            className="cart-button"
          >
            Add to Cart
          </button> */}
          {/* <button 
            onClick={() => handlePaymentClick('device', 2000)}
            className="payment-button"
          >
            {isAuthenticated() ? 'Pre-order Now!' : 'Sign in to Pre-order'}
          </button> */}
          <div className="payment-options">
            <h3> Select A Payment Method</h3>
            <div className="payment-methods">
              <label className="payment-method-label">
                <div className="payment-method-container">
                  <input
                    type="radio"
                    value="credit_card"
                    checked={paymentMethod === 'credit_card'}
                    onChange={handlePaymentMethodChange}
                  />
                  <div className="payment-method-text">
                    <span className="payment-method-name">DEBIT/CREDIT CARD</span>
                    <span className="payment-method-fee">(1.5% processing fee)</span>
                  </div>
                </div>
              </label>
              <label>
            <input 
              type="radio" 
              value="ach" 
              checked={paymentMethod === 'ach'} 
              onChange={handlePaymentMethodChange} 
            />
            ACH
          </label>
          <label>
            <input 
              type="radio" 
              value="cheque" 
              checked={paymentMethod === 'cheque'} 
              onChange={handlePaymentMethodChange} 
            />
            CHECK
          </label>
            </div>
          </div>
          <div className="terms-container">
  <label className="terms-checkbox-label">
    <input 
      type="checkbox"
      checked={acceptedTerms}
      onChange={handleTermsChange}
      className="terms-checkbox"
    />
    I agree to the
    <button 
    type="button"
    onClick={toggleTermsPopup}
    className="terms-popup-trigger"
  >
    Terms and Conditions
  </button>
</label>
</div>
               <button 
          className="payment-button"
          onClick={handlePayment}
          disabled={isProcessingOrder || !acceptedTerms}
        >{isAuthenticated() ? 'Pre-order Now!' : 'Sign in to Pre-order'}</button>
        </>
      )}
      {!preorderAvailable && (
        <div className="unavailable-message">Pre-orders are currently closed</div>
      )}
    </div>
    {!isAuthenticated() && (
      <p className="auth-note">*Sign in required to complete purchase</p>
    )}
  </div>
</div>

      {/* License Tiers */}
      <div className="license-container">
        <h2>License Tiers</h2>
        <div className="tier-cards">
          {[
            { id: 'tier_1', name: 'Tier 1', clicks: 125, price: 220 },
            { id: 'tier_2', name: 'Tier 2', clicks: 240, price: 380 },
            { id: 'tier_3', name: 'Tier 3', clicks: 500, price: 600 }
          ].map((tier) => (
            <div key={tier.id} className={`tier-card`}>
              <h3>{tier.name}</h3>
              <p className="clicks">{tier.clicks} Measurements</p>
              <p className="price">${tier.price.toFixed(2)} USD</p>
              <div className="button-group">
                {/* <button 
                  onClick={() => handleAddToCart(tier)}
                  className="cart-button"
                >
                  Add to Cart
                </button>
                <button 
                  onClick={() => handlePaymentClick(tier.id, tier.price)}
                  className="payment-button"                >
                  {isAuthenticated() ? 'Purchase' : 'Sign in to Purchase'}
                </button> */}
              </div>
              {/* {!licensesAvailable && (
                // <div className="coming-soon-overlay">
                //   <span>Available {licenseAvailableDate}</span>
                // </div>
              )} */}
            </div>
          ))}
        </div>
      </div>

      {/* Section divider */}
      <div className="section-divider"></div>

      {/* Additional Clicks */}
      <div className="additional-clicks-section">
        <h2 className="section-title">EXTRAS: Additional Measurements</h2>
        <div className="clicks-options">
          {[
            { id: 'clicks_50', name: '50 Clicks', clicks: 50, price: 140 },
            { id: 'clicks_100', name: '100 Clicks', clicks: 100, price: 280 }
          ].map((option) => (
            <div key={option.id} className="click-option">
              <p><strong>{option.clicks} Measurements </strong></p>
              <p className="price">${option.price.toFixed(2)} USD</p>
              <div className="button-group">
                {/* <button 
                  onClick={() => handleAddToCart(option)}
                  className="cart-button"
                >
                  Add to Cart
                </button> */}
                {/* <button 
                  onClick={() => handlePaymentClick(option.id, option.price)}
                  className="payment-button"
                >
                  {isAuthenticated() ? 'Purchase Now' : 'Sign in to Purchase'}
                </button> */}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="back-to-home" onClick={onBackHome}>Back to Home</div>
      
      {/* Terms and Conditions Popup */}
      {showTermsPopup && (
        <div className="popup-overlay" onClick={closePopup}>
          <div className="popup-content">
            <div className="popup-header">
              <h2>Terms and Conditions</h2>
              <button className="close-button" onClick={toggleTermsPopup}>×</button>
            </div>
            <div className="popup-body">
              <p><strong>Last Updated: March 27, 2025</strong></p>
              <p>Welcome to Digital Seed Technology! These Terms and Conditions ("Terms") govern your purchase and use of The Grady Seed Moisture Sensor (the "Product"), including any associated software, hardware, or services provided by Digital Seed Technology ("we," "us," or "our"). By purchasing or using the Product, you agree to be bound by these Terms. If you do not agree, please do not purchase or use the Product.</p>
              
              <h3>1. Product Description</h3>
              <p>The Grady Seed Moisture Sensor is a device designed to measure the moisture content of grass seed in agricultural fields. It includes GPS functionality to record field locations and may offer optional connectivity to a mobile device (e.g., smartphone or tablet) for data transmission. Features and functionality may evolve over time, and we reserve the right to modify or discontinue aspects of the Product at our discretion.</p>
              
              <h3>2. Eligibility</h3>
              <p>To purchase and use the Product, you must be at least 18 years old and have the legal capacity to enter into a binding agreement. By purchasing the Product, you represent that you meet these requirements.</p>
              
              <h3>3. Purchase and Payment</h3>
              <ul>
                <li>All purchases are subject to availability and acceptance of your order by us.</li>
                <li>Prices are as listed on our website at the time of purchase, excluding shipping fees and applicable taxes, unless otherwise stated.</li>
                <li>Payment must be made in full via the payment methods available on our website.</li>
                <li>Once your order is confirmed, you will receive a confirmation email. Title and risk of loss transfer to you upon delivery of the Product.</li>
              </ul>
              
              <h3>4. Shipping and Delivery</h3>
              <ul>
                <li>We will ship the Product to the address provided during checkout. Shipping times are estimates and not guaranteed.</li>
                <li>You are responsible for providing accurate shipping information. We are not liable for delays or losses due to incorrect or incomplete delivery details.</li>
              </ul>
              
              <h3>5. Use of the Product</h3>
              <ul>
                <li>The Product is intended for personal or commercial use to measure moisture content in grass seed. Any other use is at your own risk.</li>
                <li>You agree not to reverse-engineer, modify, or tamper with the Product's hardware or software, except as expressly permitted by applicable law.</li>
                <li>Optional mobile connectivity features require a compatible device and internet connection, which you must provide.</li>
              </ul>
              
              <h3>6. Software Updates</h3>
              <ul>
                <li>To ensure data quality and deliver new features, the Product's software will be updated on a regular basis.</li>
                <li>You are required to periodically bring the Product to a Wi-Fi-enabled environment, connect it to a Wi-Fi network, and allow the installation of software updates. Instructions for this process will be provided with the Product or on our website.</li>
                <li>Failure to install updates may result in reduced functionality, inaccurate data, or inability to access certain features. We are not liable for issues arising from your failure to update the Product as recommended.</li>
                <li>Updates are provided at no additional cost, but you are responsible for ensuring a stable internet connection during the update process.</li>
              </ul>
              
              <h3>7. Data Collection and Privacy</h3>
              <ul>
                <li>The Product collects GPS location data and moisture readings ("User Data"). If connected to a mobile device, this data may be transmitted to your device.</li>
                <li>We may collect anonymized data for product improvement and analytics purposes. We will not sell or share your personally identifiable User Data with third parties except as required by law or with your consent.</li>
                <li>Please review our Privacy Policy for details on how we handle your data.</li>
              </ul>
              
              <h3>8. Limited Warranty</h3>
              <ul>
                <li>The Product is warranted to be free from defects in materials and workmanship for a period of one (1) year from the date of purchase, under normal use.</li>
                <li>If a defect arises during the warranty period, we will, at our option, repair or replace the Product at no cost to you, provided you notify us and return the Product per our instructions.</li>
                <li>This warranty does not cover damage due to misuse, neglect, accidents, unauthorized modifications, failure to follow provided instructions, or failure to install recommended software updates.</li>
                <li>EXCEPT AS EXPRESSLY STATED HEREIN, WE DISCLAIM ALL OTHER WARRANTIES, EXPRESS OR IMPLIED, INCLUDING WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE.</li>
              </ul>
              
              <h3>9. Limitation of Liability</h3>
              <ul>
                <li>To the fullest extent permitted by law, Digital Seed Technology shall not be liable for any indirect, incidental, special, or consequential damages arising from your use of the Product, including but not limited to lost profits, data loss, or crop damage.</li>
                <li>Our total liability to you for any claim related to the Product shall not exceed the purchase price you paid.</li>
              </ul>
              
              <h3>10. Intellectual Property</h3>
              <ul>
                <li>The Product, including its hardware design, software, and any accompanying documentation, is protected by copyright, trademark, and other intellectual property laws.</li>
                <li>You are granted a non-exclusive, non-transferable license to use the Product and its software (including updates) for its intended purpose. You may not copy, distribute, or create derivative works from the Product or its software.</li>
              </ul>
              
              <h3>11. Returns and Refunds</h3>
              <ul>
                <li>No-Reason Returns: You may return the Product within 21 days of purchase for a full refund, no questions asked, provided it is unused conditions, in its original packaging, and accompanied by proof of purchase.</li>
                <li>Conditional Returns: Between 21 days and one (1) year from the date of purchase, you may return the Product for a refund or replacement only if it fails to perform as intended (e.g., does not deliver accurate moisture readings), and you provide reasonable proof of the issue (e.g., data logs, photos, or other documentation). Returns under this provision are subject to our inspection and approval.</li>
                <li>Shipping costs for returns are your responsibility unless the Product is defective or the return is due to our error.</li>
                <li>Refunds will be processed within 14 days of receiving and approving the returned Product.</li>
              </ul>
              
              <h3>12. Governing Law and Dispute Resolution</h3>
              <ul>
                <li>These Terms are governed by the laws of Oregon.</li>
                <li>Any disputes arising under these Terms shall be resolved through good-faith negotiation. If unresolved, disputes will be submitted to binding arbitration in Corvallis, Oregon under the rules of the American Arbitration Association.</li>
              </ul>
              
              <h3>13. Changes to These Terms</h3>
              <ul>
                <li>We may update these Terms from time to time. The updated version will be posted on our website with a revised "Last Updated" date. Continued use of the Product after such changes constitutes your acceptance of the new Terms.</li>
              </ul>
              
              <h3>14. Contact Us</h3>
              <p>If you have questions about these Terms or the Product, please contact us at:</p>
              <p>Email: info@digiseedtech.com</p>
              <p>By purchasing The Grady Seed Moisture Sensor, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions.</p>
            </div>
            <div className="popup-footer">
              <button className="popup-button" onClick={toggleTermsPopup}>Close</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Payments;