const RELEASE_CONFIG = {
  // License tiers availability date (2 months from now)
  LICENSE_TIERS_AVAILABLE_DATE: new Date(new Date().setMonth(new Date().getMonth() + 12)),
  // Additional clicks availability (available now)
  ADDITIONAL_CLICKS_AVAILABLE: true,
  // Pre-order availability
  PREORDER_AVAILABLE: true
};

// Helper functions to check availability
const isLicenseAvailable = () => {
  return new Date() >= RELEASE_CONFIG.LICENSE_TIERS_AVAILABLE_DATE;
};

const areAdditionalClicksAvailable = () => {
  return RELEASE_CONFIG.ADDITIONAL_CLICKS_AVAILABLE;
};

const getLicenseAvailabilityDate = () => {
  const date = RELEASE_CONFIG.LICENSE_TIERS_AVAILABLE_DATE;
  return date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });
};

const isPreorderAvailable = () => {
  return RELEASE_CONFIG.PREORDER_AVAILABLE;
};

export { 
  isLicenseAvailable, 
  areAdditionalClicksAvailable, 
  getLicenseAvailabilityDate, 
  isPreorderAvailable 
};