import React from 'react';
import './reports.css';
import chartImg from '../assets/results.png';
import backgroundImg from '../assets/reports_background.png'; // Import background image

const Reports = ({ onBackHome }) => {
  return (
    <div className="reports-page">
    <div className="reports-container">
      <h1>Testing Report</h1>
      <section className="reports-section">
        <div className="research-container">
          <div className="research-text">
            <p>
          The Grady Sensor has been tested to evaluate the correlations between the spectral reflectance values and oven-drying seed moisture content (SMC) for tall fescue, annual ryegrass, perennial ryegrass, orchardgrass and creeping bentgrass. 
          The testing procedures included taking two 10-20 g subsamples of seed to measure spectral reflectance responses. 
          Each subsample was weighed to obtain the wet weight of the seed and then dried in a labrotary oven at a 130°C for two hours. 
          The dried samples were then cooled to room temprature and weighed to determine the dry weight of the seed.
          The correlations between spectral reflectance values and oven seed moisture content are shown here.
          </p>
          </div>
          <div className="research-chart">
            <img src={chartImg} alt="Calibration Chart" className="chart-image" />
            <p className="chart-caption">Correlations between Grady Sensor readings and laboratory results.</p>
          </div>
        </div>
      </section>

      <button className="back-button" onClick={onBackHome}>Back to Home</button>
    </div>

    </div>
  );
};

export default Reports;