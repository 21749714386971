import React, { useState, useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import './navbar.css';
import { CartContext } from '../cart/cartContext';
import LogoImage from '../assets/digiseed_logo.png'

const Navbar = ({ userEmail, onSignIn, onPayments, onHome, onLearnMore, onReports, onDashboard, onContactUs }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showContactDropdown, setShowContactDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const { cart } = useContext(CartContext);
  
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };
 
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const toggleContactDropdown = () => {
    setShowContactDropdown(!showContactDropdown);
  };

  const cartItemCount = cart.reduce((total, item) => total + item.quantity, 0);

  const handleCartClick = () => {
    onPayments();
  };

  return (
    <nav className="navbar">
      {/* <h1 onClick={onHome} style={{ cursor: 'pointer' }}>Digital Seed Technology</h1> */}
      <div className="logo-container">
        <img 
          src={LogoImage} 
          alt="Digital Seed Technology Logo" 
          className="company-logo"
          style={{ width: '180px', height: '80px' }} 
        />
        {/* <span>Digital Seed Technology</span> */}
      </div>
      <div className="nav-links">
        <div className="site-branding"></div>
        <div className="nav-links">
          <div className="nav-menu">
            <button className="nav-link" onClick={onHome}>Home</button>
            <button className="nav-link" onClick={onLearnMore}>Learn More</button>
            <button className="nav-link" onClick={onReports}>Testing Report</button>
            <button className="nav-link" onClick={onPayments}>Products</button>
            <button className="nav-link" onClick={onDashboard}>User Dashboard</button>
            
            {/* Contact Us Dropdown */}
            <div className="contact-dropdown">
              <button className="nav-link" onClick={toggleContactDropdown}>
                Contact Us
              </button>
              {showContactDropdown && (
                <div className="dropdown-content">
                  <p>Email: <a href="mailto:info@digiseedtech.com">support@digiseedtech.com</a></p>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* <button className="cart-button" onClick={handleCartClick}>
          Cart ({cartItemCount})
        </button> */}
        {userEmail ? (
          <div className="user-menu" ref={dropdownRef}>
            <span onClick={toggleDropdown} className="user-name">
              {userEmail}
            </span>
            {showDropdown && (
              <div className="dropdown-menu">
                <button onClick={onPayments}>Go to Products</button>
                <button onClick={() => {
                  localStorage.removeItem('userEmail');
                  window.location.reload();
                }}>Sign Out</button>
              </div>
            )}
          </div>
        ) : (
          <button onClick={onSignIn}>Sign In</button>
        )}
      </div>
    </nav>
  );
};

Navbar.propTypes = {
  userEmail: PropTypes.string,
  onSignIn: PropTypes.func.isRequired,
  onPayments: PropTypes.func.isRequired,
  onDashboard: PropTypes.func.isRequired,
  onHome: PropTypes.func.isRequired,
  onLearnMore: PropTypes.func.isRequired,
  onReports: PropTypes.func.isRequired
};

export default Navbar;