import React from 'react';
import './learnmore.css';
const LearnMore = ({onBackHome}) => {
    return (
        <div className="container">
            <h1>More about The Grady Sensor</h1>
            <div className="about-the-sensor">
            <p className="sensor-text">The Original Grady Sensor, first produced by Digital Seed Technology in 2025, is named after 
                Dr. Thomas Grady Chastain.  
                Dr. Chastain received his Ph.D. in Crop Science from Oregon State University (OSU) in 1987. 
                He returned to OSU in 1989 as an Assistant Professor in Seed Crop Physiology and Ecology and 
                retired as Head of the Crop and Soil Science Department at OSU in 2024.  
                He served on the Board of the Crop Science Society of America and is Past-President of the International Herbage Seed Group.  
                Dr. Chastain spent his research and teaching career focused on seed.  
                His interests included seed germination and stand establishment, seed development, and seed production. 
                Dr. Chastain was not only recognized an outstanding classroom teacher, but also as someone who made important scientific
                 contributions which have improved our understanding of seed crop physiology, particularly in herbage seed crops.
                Investigating whether NIR sensor technology could be used to measure seed moisture content in grass seed crops was one of 
                Dr. Chastain’s final research questions while at OSU. Drs Anderson and Zhou are grateful to have had the opportunity to investigate this question, 
                validate findings, and develop The Grady Sensor - a practical agricultural sensor product - 
                now available to the global seed industry.
</p>
</div>
    <div className="back-to-home" onClick={onBackHome}>Back to Home</div>

        </div>

    );
};

export default LearnMore;